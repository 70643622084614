/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState, Fragment } from 'react'
import { Button, Flex, Text, Spinner, jsx } from 'theme-ui'
import { useCart, useTranslate, utils } from '@arfabrands/gatsby-theme-assembly'

const ProductAddToCartButton = ({
  soldOut = false,
  comingSoon = false,
  disabled = false,
  price,
  quantity,
  regularPrice,
  sku,
  handleAddToCart,
  pdp,
  kit,
  ...props
}) => {
  const translate = useTranslate()
  const { addToCart } = useCart()
  const { toUsdCurrency } = utils
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const [apiError, setApiError] = useState(null)

  let buttonStyle = {
    backgroundColor: '#fff',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: 'rgb(81, 39, 115)'
    },
    border: '2px solid rgb(81, 39, 115)',
    borderRadius: '0.25rem',
    color: 'rgb(81, 39, 115)',
    display: 'block',
    fontSize: ['13px', null, '14px'],
    fontFamily: 'DIN Alternate Bold,Helvetica,Arial,Sans-Serif',
    letterSpacing: 'normal',
    padding: '0.75rem 0.5rem',
    width: '100%',
  }

  if (pdp) {
    if (kit) {
      buttonStyle.backgroundColor =  'rgb(81, 39, 115)'
      buttonStyle.color = '#fff'
      buttonStyle['&:hover'] = {
        filter: 'brightness(0.9)',
        backgroundColor: 'rgb(81, 39, 115)',
      }
    } else {
      buttonStyle.backgroundColor =  'rgb(255, 199, 10)'
      buttonStyle['&:hover'] = {
        color: 'rgb(81, 39, 115)',
        backgroundColor: 'rgb(255, 199, 10)',
        filter: 'brightness(0.9)',
      }
    }

    buttonStyle.border = 'none'
    buttonStyle.marginTop = '4px !important'
    buttonStyle.width = '90%'

  }

  const handleSubmit = async event => {
    if (event) event.preventDefault()
    if (disabled || comingSoon || soldOut) return

    setButtonIsLoading(true)
    setApiError(null)

    try {
      await addToCart(sku, quantity)
    } catch (error) {
      setApiError(translate('error.api.default'))
    } finally {
      window.location.href = '/cart/'
    }

    setButtonIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit} sx={{
      flexBasis: '100%',
    }}>
      <Button
        disabled={disabled || comingSoon || soldOut}
        {...props}
        p={0}
        type="submit"
        sx={buttonStyle}
      >
        <Flex sx={{
          height: '100%',
          justifyContent: 'center',
         }}>
          <Flex
            sx={{
              height: '100%',
              padding: ['0 16px', null, '0'],
              minWidth: 'auto',
            }}
          >
            {buttonIsLoading && (
              <Spinner data-testid="spinner" size="15" color="inherit" />
            )}
            {!buttonIsLoading && (
              <Fragment>
                {comingSoon && translate('product.coming_soon')}
                {soldOut && translate('product.sold_out')}
                {!comingSoon && !soldOut && translate('product.add_to_cart')}
              </Fragment>
            )}
          </Flex>
          {!buttonIsLoading && (
            <Flex sx={{
              margin: '0 0.5rem',
              minWidth: 'auto',
            }}> — </Flex>
          )}
          {!buttonIsLoading && (
            <Flex
              sx={{
                height: '100%',
                fontSize: ['13px', null, '14px'],
                padding: ['0 16px', null, '0'],
                minWidth: 'auto',
              }}
            >
              {regularPrice !== price ? (
                <Flex
                  sx={{
                  }}
                >
                  <span sx={{
                    marginRight: '0.5rem',
                  }}>
                    {toUsdCurrency(price)}
                  </span>
                  <span
                    sx={{
                      textDecoration: 'line-through',
                    }}
                  >
                    {toUsdCurrency(regularPrice)}
                  </span>
                </Flex>
              ) : (
                <Text>{toUsdCurrency(price)}</Text>
              )}
            </Flex>
          )}
        </Flex>
      </Button>
      {apiError && (
        <Text color="errorDark" variant="textLink" mt="1.5rem">
          {apiError.toString()}
        </Text>
      )}
    </form>
  )
}

ProductAddToCartButton.propTypes = {
  soldOut: PropTypes.bool,
  comingSoon: PropTypes.bool,
  disabled: PropTypes.bool,
  price: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  regularPrice: PropTypes.number,
  sku: PropTypes.string.isRequired,
  pdp: PropTypes.bool,
  kit: PropTypes.bool,
}

export default ProductAddToCartButton
