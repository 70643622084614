/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'

const CollectionFilterBarTitleDesktop = ({ sx, ...props }) => {
  const translate = useTranslate()

  return (
    <Box
      sx={{
        fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
        variant: 'text.navLink',
        color: 'rgb(0, 93, 95)',
        display: ['none', 'flex'],
        paddingRight: '1em',
        paddingTop: '1.25em',
        paddingBottom: '.75em',
        ...sx
      }}
      {...props}
    >
      Shop by Collection
    </Box>
  )
}

export default CollectionFilterBarTitleDesktop
