/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Container, Flex, Heading, jsx } from 'theme-ui'
import KitList from '~/components/Kit/List'
import ProductList from '~/components/Product/List'

const CollectionList = ({ collection }) => {
  const { title, description, products, kits } = collection
  return (
    <Container
      sx={{
        maxWidth: '1440px',
        paddingTop: ['12px', '0', null],
        padding: '1rem'
      }}
    >
      <Flex
        sx={{
          alignItems: 'left',
          flexDirection: ['column', 'column'],
          justifyContent: 'space-between',
          padding: ['16px 1.5rem', null, '32px 0'],
          width: '100%'
        }}
      >
        <Heading
          as="h2"
          sx={{
            color: 'rgb(0, 93, 95)',
            fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
            fontSize: ['34px', '55px'],
            fontWeight: 'normal',
            lineHeight: '60px',
            paddingRight: [null, '5rem'],
          }}
        >
          {title}
        </Heading>
        {description && (
          <Box
            sx={{
              color: 'rgb(0, 93, 95)',
              fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
              fontSize: ['14px', '16px'],
              '& p': {
                margin: 0
              }
            }}
            variant="text.subhead"
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html
            }}
          />
        )}
      </Flex>
      <Box sx={{
        padding: [null, '1rem', null]
      }}>
        {products && <ProductList products={products} />}
        {kits && <KitList kits={kits} />}
      </Box>
    </Container>
  )
}

CollectionList.propTypes = {
  collection: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.shape({}),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string,
        slug: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.number,
        mainImage: PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string
        }),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string
          })
        ),
        comingSoon: PropTypes.bool
      })
    ),
    kits: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        price: PropTypes.number,
        regularPrice: PropTypes.number,
        products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        soldOut: PropTypes.bool,
        comingSoon: PropTypes.bool,
        description: PropTypes.shape({}),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string
          })
        )
      })
    ),
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  })
}

export default CollectionList
