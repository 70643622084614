import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ProductListPage from '~/components/Product/ListPage'

const constants = {
  backgroundColor: 'rgb(145, 211, 202)',
  navTextColor: '#005D5F',
  logoColor: '#005D5F',
}

const Shop = ({ data }) => {
  const { page, catalog } = data

  return (
    <Layout
      backgroundColor={constants.backgroundColor}
      navTextColor={constants.navTextColor}
      logoColor={constants.logoColor}
    >
      <Metadata title={page.name} />
      <ProductListPage page={page} catalog={catalog} />
    </Layout>
  )
}

export default Shop

export const query = graphql`
  query ShopPage($locale: String) {
    catalog: contentfulCatalog(
      slug: { eq: "catalog" }
      node_locale: { eq: $locale }
    ) {
      collections {
        ...CollectionDetailsFragment
      }
    }
    page: contentfulPage(slug: { eq: "shop" }, node_locale: { eq: $locale }) {
      name
      ...ContentHeroFragment
    }
  }
`
