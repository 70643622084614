/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, jsx } from 'theme-ui'
import CollectionFilterBarFilter from './Filter'

const CollectionFilterBarFilters = ({
  menuOpen = false,
  options,
  onClick,
  currentCollection,
  sx,
  ...props
}) => {
  return (
    <Flex
      sx={{
        fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
        variant: 'text.navLink',
        color: 'rgb(0, 93, 95)',
        display: [menuOpen ? 'flex' : 'none', 'flex'],
        flexDirection: ['column', 'row'],
        justifySelf: ['left', 'center'],
        ...sx
      }}
      {...props}
    >
      {options.map(({ title, slug }) => (
        <CollectionFilterBarFilter
          key={slug}
          slug={slug}
          currentCollection={currentCollection}
          title={title}
          onClick={onClick(slug)}
        />
      ))}
    </Flex>
  )
}

CollectionFilterBarFilters.propTypes = {
  menuOpen: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
  currentCollection: PropTypes.shape({
    slug: PropTypes.string
  })
}

export default CollectionFilterBarFilters
