/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { useAnalytics } from '@arfabrands/gatsby-theme-assembly'

const ProductLink = ({ slug, children, sx, ...props }) => {
  const { trackClickProduct } = useAnalytics()

  const url = `/products/${slug}/`

  return (
    <Link
      to={url}
      onClick={() => trackClickProduct(slug)}
      sx={{
        ...sx
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

ProductLink.propTypes = {
  slug: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default ProductLink
