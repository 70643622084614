/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, jsx } from 'theme-ui'
import Chevron from '~/assets/images/icons/chevron.svg'
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'

const CollectionFilterBarTitle = ({
  onClick,
  currentCollection,
  menuOpen = false,
  sx,
  ...props
}) => {
  const translate = useTranslate()

  return (
    <Flex
      onClick={onClick}
      sx={{
        fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
        variant: 'text.navLink',
        color: 'rgb(0, 93, 95)',
        display: [null, 'none'],
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
        padding: ['20px 20px', 0],
        ...sx
      }}
      {...props}
    >
      {currentCollection && !menuOpen
        ? currentCollection.title
        : 'Shop by Collection'}
      <Chevron />
    </Flex>
  )
}

CollectionFilterBarTitle.propTypes = {
  onClick: PropTypes.func,
  currentCollection: PropTypes.shape({
    title: PropTypes.string
  }),
  menuOpen: PropTypes.bool
}

export default CollectionFilterBarTitle
