/** @jsx jsx */
import { useAnalytics } from '@arfabrands/gatsby-theme-assembly'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { Box, Card, Flex, jsx } from 'theme-ui'
import ChooseKitButton from '~/components/Kit/ChooseKitButton'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'
import KitLink from '~/components/Kit/Link'
import { useState } from 'react';

const KitCard = ({ kit }) => {
  const { trackClickProduct } = useAnalytics()

  const {
    name,
    slug,
    shortDescription,
    price,
    regularPrice,
    comingSoon,
    soldOut,
    products,
    mainImage,
    hoverImage
  } = kit

  const hasVariants = products.filter(p => p.variants.length > 1).length > 0

  const url = `/bundles/${slug}/`

  const [hovering, setHovering] = useState(false);

  return (
    <Card
      sx={{
        background: 'white',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        flexDirection: 'column',
        padding: 0,
        marginBottom: '1rem',
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          paddingBottom: ['8px'],
        }}
      >
        <KitLink slug={slug} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
          <Box sx={{
            position: 'relative',
            opacity: hovering ? 0 : 1,
            transition: 'opacity 0.5s ease-in-out',
          }}>
            <Img
              sx={{
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
              }}
              fluid={mainImage.fluid}
              alt={mainImage.title}
              key={mainImage.title}
            />
          </Box>
          <Box sx={{
            position: 'absolute',
            top: 0,
            opacity: hovering ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            width: '100%',
          }}>
            <Img
              sx={{
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
              }}
              fluid={hoverImage.fluid}
              alt={hoverImage.title}
              key={hoverImage.title}
            />
          </Box>
        </KitLink>
        <Flex
          pt={['0.5rem', '0.5rem', '1.25rem']}
          sx={{
            alignItems: 'center',
            flexDirection: 'column',
            padding: '1rem',
          }}
        >
          <Link
            sx={{
              display: 'block',
              textTransform: 'none',
              variant: ['text.h3', 'text.h3', 'text.h3'],
              textAlign: 'left',
              marginBottom: ['4px', null, '6px']
            }}
            to={url}
          >
            {name}
          </Link>
          <Box
            sx={{
              textAlign: 'center',
              variant: 'text.small'
            }}
          >
            {shortDescription}
          </Box>
        </Flex>
      </Flex>
      <Box sx={{
        padding: '0.5rem 1rem 1rem',
      }}>
        {hasVariants ? (
          <ChooseKitButton slug={slug} />
        ) : (
          <ProductAddToCartButton
            soldOut={soldOut}
            comingSoon={comingSoon}
            price={price}
            quantity={1}
            regularPrice={regularPrice}
            sku={products
              .map(p => p.variants.map(v => v.sku))
              .sort()
              .join('|')}
            sx={{ width: '100%' }}
          />
        )}
      </Box>
    </Card>
  )
}

KitCard.propTypes = {
  kit: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    soldOut: PropTypes.bool,
    comingSoon: PropTypes.bool,
    description: PropTypes.shape({}),
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    }),
    hoverImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string
      })
    )
  })
}

export default KitCard
