/** @jsx jsx */
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { Box, Card, Flex, jsx } from 'theme-ui'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'
import ProductChooseVariantButton from '~/components/Product/ChooseVariantButton'
import ProductLink from '~/components/Product/Link'
import { useState } from 'react';

const ProductCard = ({ product }) => {
  const {
    collection: allCollections = [],
    mainImage,
    hoverImage,
    name,
    slug,
    shortDescription,
    variants
  } = product
  const collection = allCollections.find(collection => collection)

  // each product have at least one variant (enforced by Contentful model)
  const { soldOut, comingSoon, price, regularPrice, size, sku } = variants[0]

  const [hovering, setHovering] = useState(false);

  return (
    <Card
      sx={{
        background: 'white',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 0,
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <ProductLink slug={slug} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
          <Box sx={{
            position: 'relative',
            opacity: hovering ? 0 : 1,
            transition: 'opacity 0.5s ease-in-out',
          }}>
            <Img
              sx={{
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                height: '450px',
              }}
              fluid={mainImage.fluid}
              alt={mainImage.title}
              key={mainImage.title}
            />
          </Box>
          <Box sx={{
            position: 'absolute',
            top: 0,
            opacity: hovering ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            width: '100%',
          }}>
            <Img
              sx={{
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                height: '450px',
              }}
              fluid={hoverImage.fluid}
              alt={hoverImage.title}
              key={hoverImage.title}
            />
          </Box>
        </ProductLink>

        <Flex
          pt={['0.5rem', '0.5rem', '1.25rem']}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '1rem',
            minHeight: '10rem',
          }}
        >
          <ProductLink
            sx={{
              display: 'block',
              textTransform: 'none',
              variant: ['text.h3', 'text.h3', 'text.h3'],
              textAlign: ['center', 'left'],
              marginBottom: ['4px', null, '6px']
            }}
            slug={slug}
          >
            {name}
          </ProductLink>
          <ProductLink
            sx={{
              display: 'block',
              fontSize: ['13px', '16px'],
              textTransform: 'none',
              variant: 'text.small',
              textAlign: 'center'
            }}
            slug={slug}
          >
            {shortDescription}
          </ProductLink>
        </Flex>
      </Flex>
      <Box sx={{
        padding: '0.5rem 1rem 1rem',
        width: '100%',
      }}>
        <Flex
          color="primary"
          sx={{
            justifyContent: 'center',
            paddingBottom: ['8px', '8px', '16px'],
          }}
        >
          {size && (
            <ProductLink
              sx={{
                variant: 'text.navLink',
                justifyContent: 'center'
              }}
              slug={slug}
            >
              {size}
            </ProductLink>
          )}
        </Flex>
        {variants.length === 1 ? (
          <ProductAddToCartButton
            soldOut={soldOut}
            comingSoon={comingSoon}
            price={price}
            quantity={1}
            regularPrice={regularPrice}
            sku={sku}
            sx={{ width: '100%' }}
          />
        ) : (
          <ProductChooseVariantButton slug={slug} />
        )}
      </Box>
    </Card>
  )
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    collection: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        title: PropTypes.string
      })
    ),
    comingSoon: PropTypes.bool,
    soldOut: PropTypes.bool,
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    }),
    name: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    size: PropTypes.string,
    sku: PropTypes.string,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
    variants: PropTypes.arrayOf(PropTypes.shape({}))
  })
}

export default ProductCard
