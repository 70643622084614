/** @jsx jsx */
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'
import PropTypes from 'prop-types'
import { Button, Flex, jsx } from 'theme-ui'
import KitLink from '~/components/Kit/Link'

const ChooseKitButton = ({ disabled = false, slug, ...props }) => {
  const translate = useTranslate()

  return (
    <Button
      disabled={disabled}
      {...props}
      p={0}
      type="submit"
      sx={{
        backgroundColor: '#fff',
        '&:hover': {
          color: '#ffffff',
          backgroundColor: 'rgb(81, 39, 115)'
        },
        border: '2px solid rgb(81, 39, 115)',
        borderRadius: '0.25rem',
        color: 'rgb(81, 39, 115)',
        display: 'block',
        fontSize: ['13px', null, '14px'],
        fontFamily: 'DIN Alternate Bold,Helvetica,Arial,Sans-Serif',
        letterSpacing: 'normal',
        padding: '0.75rem 0.5rem',
        width: '100%',
      }}
    >
      <Flex sx={{ height: '100%' }}>
        <Flex
          sx={{
            alignItems: 'center',
            flexGrow: 1,
            height: '100%',
            justifyContent: 'center',
            padding: ['0 16px', null, '0 32px']
          }}
        >
          <KitLink slug={slug}>{translate('product.choose_kit')}</KitLink>
        </Flex>
      </Flex>
    </Button>
  )
}

ChooseKitButton.propTypes = {
  disabled: PropTypes.bool,
  slug: PropTypes.string.isRequired
}

export default ChooseKitButton
