/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'

const CollectionFilterBarFilter = ({
  title,
  slug,
  onClick,
  currentCollection
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        textDecoration:
          currentCollection && currentCollection.slug === slug
            ? 'underline'
            : 'none',
        cursor: 'pointer',
        textAlign: ['left', 'center'],
        padding: ['10px 20px', '0 20px 0 0']
      }}
    >
      {title}
    </Box>
  )
}

CollectionFilterBarFilter.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string,
  onClick: PropTypes.func,
  currentCollection: PropTypes.shape({
    slug: PropTypes.string
  })
}

export default CollectionFilterBarFilter
